<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
        <div class="edit-header">
          <span>建设工程竣工规划核实项目</span>
          <el-button type="primary"
                     v-show="!isDisabled"
                     @click="onSubmit">保存</el-button>
        </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form ref="baseform"
                 :label-position="labelPosition"
                 :model="form"
                 label-width="120px">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="案卷编号:">
                <el-input v-model="form.AJBH"
                          disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="项目代码:">
                <el-input v-model="form.XMDM"
                          disabled></el-input>
              </el-form-item>
              <el-form-item label="项目编号:">
                <el-input v-model="form.XMBH"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="建设工程规划许可证号:">
                <el-input v-model="form.JSGCGHXKZH"
                          :disabled="isDisabled">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">

              <el-form-item label="证书:">
                <el-input v-model="form.ZS"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
              <el-form-item label="核发日期:">
                <el-date-picker style="width: 100%"
                                v-model="form.HFRQ"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                                placeholder="请选择核发日期"
                                :disabled="isDisabled">
                </el-date-picker>
              </el-form-item>

              <el-form-item label="所属分局:">
                <el-select v-model="form.SSFJ"
                           style="width: 100%"
                           :disabled="isDisabled">
                  <el-option v-for="item in options1"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发证机关:">
                <el-input v-model="form.FZJG"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
              <el-form-item label="建设单位名称:">
                <el-input v-model="form.JSDWMC"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
              <el-form-item label="项目名称:">
                <el-input v-model="form.XMMC"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
              <el-form-item label="建设位置:">
                <el-input v-model="form.JSWZ"
                          :disabled="isDisabled">
                </el-input>
              </el-form-item>
              <el-form-item label="建设规模:">
                <el-input v-model="form.JSGM"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
              <el-form-item label="主建筑性质:">
                <el-input v-model="form.ZJJXZ" style="width: 100%"
                          :disabled="isDisabled"></el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="总用地面积:">
                <el-input v-model="form.ZYDMJ"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总建筑面积:">
                <el-input v-model="form.ZJJMJ"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地上建筑面积:">
                <el-input v-model="form.DSJJMJ"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地上住宅面积:">
                <el-input v-model="form.DSZZMJ"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地上商业面积:">
                <el-input v-model="form.DSSYMJ"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地上办公面积">
                <el-input v-model="form.DSBGMJ"
                          :disabled="isDisabled"> <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地上工业面积:">
                <el-input v-model="form.DSGYMJ"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地上物业办公面积:">
                <el-input v-model="form.DSWYBGMJ"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地上物业经营面积:">
                <el-input v-model="form.DSWYJYMJ"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地上社区用房面积:">
                <el-input v-model="form.DSSQYFMJ"
                          :disabled="isDisabled">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地下建筑面积:">
                <el-input v-model="form.DXJZMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地下商业面积">
                <el-input v-model="form.DXSYMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地下办公面积">
                <el-input v-model="form.DXBGMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地下娱乐面积">
                <el-input v-model="form.DXYLMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地下仓储面积">
                <el-input v-model="form.DXCCMJ"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地下停车及其他面积">
                <el-input v-model="form.DXTCMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="计容建筑面积">
                <el-input v-model="form.JRJZMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="容积率">
                <el-input v-model="form.RJL"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="建筑密度">
                <el-input v-model="form.JZMD"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="绿地率">
                <el-input v-model="form.LVL"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="建筑高度">
                <el-input v-model="form.JZGD"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="机动车停车位">
                <el-input v-model="form.JDCTCW"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="机动车停车位地下">
                <el-input v-model="form.JDCTCWDX"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="非机动车停车位">
                <el-input v-model="form.FJDCTCW"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="非机动车停车位面积">
                <el-input v-model="form.FJDCTCWMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="非机动车停车位地上">
                <el-input v-model="form.FJDCTCWDS"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="非机动车停车位地上面积">
                <el-input v-model="form.FJDCTCWDSMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="非机动车停车位地下">
                <el-input v-model="form.FJDCTCWDX"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="非机动车停车位地下面积">
                <el-input v-model="form.FJDCTCWDXMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="住宅总户数">
                <el-input v-model="form.ZZZHS"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地下建筑开发类型及面积">
                <el-input v-model="form.DXJZKFLXJMJ"
                          :disabled="isDisabled"><template #append>平方米</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开发层数">
                <el-input v-model="form.KFCS"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业务局">
                <el-input v-model="form.YWJ"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="室外地坪标高">
                <el-input v-model="form.SWDPBG"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="经办人姓名">
                <el-input v-model="form.JBRXM"
                          disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经办时间">
                <el-date-picker style="width:100%"
                                v-model="form.JBSJ"
                                disabled></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注:">
                <el-input v-model="form.BZ"
                          type="textarea"
                          :rows="3"
                          :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
  </div>
</template>


<script>
import { ElMessage } from 'element-plus'
import FloatTitBtn from '../../components/FloatTitBtn.vue'
export default {
  name:'jsgcjgghhs',
  components: { FloatTitBtn },
  data() {
    return {
      labelPosition: 'left',
      form: {
        Id: '',
        BJID: '',
        JSGCGHXKZH: '',
        XMBH: '',
        XMDM: '',
        AJBH: '',
        ZS: '',
        HFRQ: '',
        SSFJ: '',
        FZJG: '',
        JSDWMC: '',
        XMMC: '',
        JSWZ: '',
        JSGM: '',
        ZJJXZ: '',
        ZYDMJ: '',
        ZJJMJ: '',
        DSJJMJ: '',
        DSZZMJ: '',
        DSSYMJ: '',
        DSBGMJ: '',
        DSGYMJ: '',
        DSWYBGMJ: '',
        DSWYJYMJ: '',
        DSSQYFMJ: '',
        DXJZMJ: '',
        DXSYMJ: '',
        DXBGMJ: '',
        DXYLMJ: '',
        DXCCMJ: '',
        DXTCMJ: '',
        JRJZMJ: '',
        RJL: '',
        JZMD: '',
        LVL: '',
        JZGD: '',
        JDCTCW: '',
        JDCTCWDS: '',
        JDCTCWDX: '',
        FJDCTCW: '',
        FJDCTCWMJ: '',
        FJDCTCWDS: '',
        FJDCTCWDSMJ: '',
        FJDCTCWDX: '',
        FJDCTCWDXMJ: '',
        ZZZHS: '',
        DXJZKFLXJMJ: '',
        KFCS: '',
        YWJ: '',
        SWDPBG: '',
        JBR: '',
        JBRXM: '',
        JBSJ: new Date(),
        QLSJ: new Date(),
        QLRY: '',
        ZJSJ: new Date(),
        ZJRY: '',
        ZJYJ: '',
        BZ: '',
      },
      options1:[],
      isDisabled: true,
    }
  },
  methods: {
    async fetch(Id) {
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi + '/api/YW/Getjsgcjgghhs?BJID=' + Id
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              this.form = response.data.Data
              this.form.AJBH = this.$store.state.AJH
              this.form.XMDM = this.$store.state.XMDM
            } else {
              console.log(response.data.ErrorMessage)
            }
            if (
              this.form.JBR === this.$router.user.profile.sub &&
              this.$route.query.taskId
            ) {
              this.isDisabled = false
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    async onSubmit() {
      let me = this;
      try {
        await this.$ajax
          .post(
            this.$appConfig.apiUrls.ywxtApi + '/api/YW/AddOrUpdatejsgcjgghhs',
            this.form
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form.Id = response.data.Data;
              ElMessage({
                type: 'success',
                message: '保存成功!',
              })
            } else {
              ElMessage({
                type: 'info',
                message: '保存失败!',
              })
              console.log(response.data.ErrorMessage)
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    async getssfj() {
      let me = this
      await this.$ajax
        .get(
          me.$appConfig.apiUrls.ywxtApi +
            '/api/Dictionary/GetDictionary?code_class=1'
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            response.data.Data.forEach(function (value) {
              me.options1.push({ value: value.code, label: value.code_value })
            })
          } else {
            console.log(response.data.errorMessage)
          }
        })
    },
  },
  mounted() {
    this.form.BJID = this.$route.query.instanceId ?? ''
    this.fetch(this.form.BJID)
    this.getssfj()
  },
}
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
</style>
